import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import SwaggerUIComponent from './SwaggerUIComponent'

const App = () => {

  return (
    <Router hashType="slash" >
      <Switch>
        <Route exact path="/" >
          <SwaggerUIComponent />
        </Route>
        <Route path="/:api" >
          <SwaggerUIComponent />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
