import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SwaggerUI from 'swagger-ui'
import '../node_modules/swagger-ui/dist/swagger-ui.css';
import MsMap from './ms-map.json'

import { useParams } from "react-router";
import { AppBar, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    maxWidth: 200,
    minWidth: 200
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  label: {
    textTransform: 'capitalize',
  },
  logoBlueColor: {
    color: '#2f4a9f'
  }
}));

const SwaggerUIComponent = () => {

  let { api } = useParams();

  useEffect(() => {
    let uri = MsMap[api] ? MsMap[api].uri : "apis/index.yaml";
    SwaggerUI({
      dom_id: '#swagger-ui-content',
      url: uri
    })
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} color="default" >
        <Toolbar>
          <NavLink to="/">
            <img src="ibk_logo.svg" alt="logo" className={classes.logo} />
          </NavLink>
          <Typography variant="caption">
            <strong className={classes.logoBlueColor}>API</strong>
          </Typography>
          <div className={classes.toolbarButtons}>
            {Object.entries(MsMap).map(([key, value]) => (
              <Button href={"#/" + key} key={key} className={classes.label} >{value.name}</Button>
            ))}
          </div>

        </Toolbar>
      </AppBar>
      <main className={classes.content}  >
        <Container fixed>
          <Toolbar />
          <div id="swagger-ui-content" />
        </Container>
      </main>
    </div>
  );
}

export default SwaggerUIComponent;
